import { ACTION_NAMES, ActionContext, ACTIONS, Automation, FlowCall } from 'prace-common-components'
import { Template } from 'store/api/templates/types'

export const anyActionError = (actions: Automation[], templates: Template[]): Automation | undefined => {
  let errorAction: Automation | undefined
  actions.forEach((action: Automation) => {
    if(action.condition?.conditionalElement || action.condition?.type) {
      if((!(action.condition.conditionalElement as { id?: number })?.id && !!action.condition.type) || 
      (!!(action.condition.conditionalElement as { id?: number })?.id && !action.condition.type))
        errorAction = action
    }
    switch(action.type) {
      case ACTIONS.NOTIFY: {
        const context = action.context as ActionContext[ACTIONS.NOTIFY]
        if(!context?.notificationId) errorAction = action
        const template = templates.find((template) =>
          (template.id === context?.notificationId || template.id === context?.notification?.id))
        if(!template) errorAction = action
        break
      }
      case ACTIONS.VISIBILITY:
      case ACTIONS.ASSIGN: {
        const context = action.context as ActionContext[ACTIONS.VISIBILITY]
        if(!context?.flowElement || !context?.flowElement?.id) errorAction = action
      }
    }
  })
  return errorAction
}

export const validateEditCall = (call: FlowCall, templates: Template[]) => {
  const steps = call.flow || []
  /* Check if every step has assignees */
  const stepAssigneeError = steps.find((step) => !step.permissions.assignable?.length)
  if(stepAssigneeError)
    return `Step ${stepAssigneeError.title} does not have assignees`

  /* Check if every step that has assignees also has assigners (except first step) */
  const stepAssignError = steps.find(
    (step) => step.order && step.permissions.assignable?.length && !step.permissions.assign?.length)
  if(stepAssignError)
    return `Step ${stepAssignError.title} does not have assigners`

  /* Check if every step has forms */
  const stepElementsError = steps.find((step) => !step.flow?.length)
  if(stepElementsError)
    return `Step ${stepElementsError.title} does not have any form`

  /* Check if every form has elements */
  const formElementsError = steps.find((step) => !!step?.flow.find((form) => !form.flow?.length))
  if(formElementsError)
    return `Step ${formElementsError.title} has a form without elements`


  /* Check if there are  cutoffs with no start dates */
  const cutoffError = call.cutoffs.find((cutoff) => !cutoff.start)
  if(cutoffError) return 'There is a cutoff with no start date'

  /* Check if there are submission timeframes with no start and end dates */
  for (const step of steps) {
    if (step.timeframes && !step.order) {
      const timeframeError = step.timeframes.find((timeframe) => !timeframe.start || !timeframe.end)
      if (timeframeError)
        return `Step ${step.title} has a timeframe without start or end dates`
    }
  }

  /* Check if there is any automation with an error */
  const allAutomations: Automation[] = steps.map((step) => step.automations).flat()
  const actionError = anyActionError(allAutomations, templates)
  if(actionError) {
    const step = steps.find((step) => step.id == actionError?.parentId)
    return `${ACTION_NAMES[actionError.type]} automation #${actionError.id} from Step ${step?.title || '-'} has an error`
  }

  return ''
}
