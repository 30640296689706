import Grid from '@material-ui/core/Grid'
import { SvgIcon } from 'prace-common-components'
import styled from 'styled-components'

export const $Grid = styled(Grid)`
  height: 100%;
`

export const $GridItem = styled(Grid)`
  padding: ${({ theme }) => `0 ${theme.spacing(3)}`};
`

export const $EditCallgrid = styled(Grid)`
  &>div {
    margin-bottom: ${({ theme }) => theme.spacing(4)};
  }
`

export const $GridFlow = styled(Grid)`
  padding: ${({ theme }) => `0 ${theme.spacing(3)}`};
  padding-left: 0;
`

export const $GridDrawer = styled(Grid)`
  padding: ${({ theme }) => `0 ${theme.spacing(3)}`};
  padding-right: 0;
`

export const $GridCollapseContainer = styled(Grid)`
  position: sticky;
  top: 150px;
  &>div, div {
    max-width: 300px;
  }
`

export const $GridColumnContainer = styled(Grid)`
  position: sticky;
  top: 150px;
`

export const $BackButton = styled.div`
  color: ${({ theme }) => theme.colors.darkPrimary};
  cursor: pointer;
  display: flex;
  align-items: center;
`

export const $TopBar = styled(Grid)`
  padding: 0;
  position: sticky;
  top: 60px;
  z-index: 6;
  background-color: ${({ theme }) => theme.colors.white};
  ${({theme}) => theme.elevations[0]};
`

//TODO: Same as $TemplateTitle
export const $CallTitle = styled(Grid)`
  white-space: nowrap;
  gap: ${({ theme }) => theme.spacing(1)};
  & > div {
    max-width: fit-content;
  }
`

export const $Save = styled.div`
  margin-left: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.colors.primary};
`

export const $Warning = styled(SvgIcon)`
  animation: pulse 3s 1;
  border-radius: 50%;

  @keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(168, 63, 57, 0.3), inset 0 0 0 100px rgba(168, 63, 57, 0.3);
    }
    5% {
        transform: scale(1.01);
    }
    15% {
        transform: scale(1);
    }
    70% {
        box-shadow: 0 0 0 50px rgba(168, 63, 57, 0), inset 0 0 0 50px rgba(168, 63, 57, 0);
    }
    100% {
        box-shadow: 0 0 0 100px rgba(168, 63, 57, 0), inset 0 0 0 0 rgba(168, 63, 57, 0);
    }
  }
`
