import 'date-fns'
import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import {
  createBrowserRouter,
  RouterProvider,
  RouteObject,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { NewGlobalStyle as GlobalStyle, newTheme, ErrorMonitor } from 'prace-common-components'
import { ThemeProvider } from 'styled-components'
import { App } from './App'
import { store } from 'store'
import { useGetThemeQuery } from 'store/api/auth'
import { createTheme } from '@material-ui/core/styles'
import { __LOCAL__, CDN } from 'constants/global'

const routes: RouteObject[] = [
  {
    path: '*',
    element: <App />,
  },
]

// Create the router
const router = createBrowserRouter(routes, {
  basename: '/',
})

// Error reporting API
Sentry.init({
  dist: SENTRY_DIST,
  dsn: SENTRY_DNS,
  environment: SENTRY_ENV,
  normalizeDepth: 10,
  enabled: !__LOCAL__,
  tracesSampleRate: 1,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
})

const ThemeApp = () => {
  const { data: theme } = useGetThemeQuery()
  const customTheme = theme ? { ...newTheme, palette: theme.palette, colors: { ...newTheme.colors, ...theme.colors }} 
    : newTheme

  useEffect(() => {
    if(!theme) return
    document.title = `${theme.organization.shortName || ''} - Admin Portal`
    let link: HTMLLinkElement | null = document.querySelector('link[rel~=\'icon\']')
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      document.getElementsByTagName('head')[0].appendChild(link)
    }
    link.href = `${CDN}/favicon.ico`
  }, [theme])

  return <ThemeProvider theme={createTheme(customTheme)}>
    <RouterProvider router={router} />
  </ThemeProvider>
}

const root = createRoot(document.getElementById('root')!)

const ProvidedApp = <Sentry.ErrorBoundary fallback={({ error, componentStack }) => (
  <ErrorMonitor error={error} componentStack={componentStack || ''} env={ENV}/>
)} showDialog>
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <Provider store={store}>
      <GlobalStyle />
      <ThemeApp />
    </Provider>
  </MuiPickersUtilsProvider>
</Sentry.ErrorBoundary>

root.render(ProvidedApp)
