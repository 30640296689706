import * as React from 'react'
import { useOthers, useStatus, useEventListener, useUpdateMyPresence, useBroadcastEvent } from '../../liveblocks.config'
import { $Chip, $MultiUserFeedback, $OnlineUsers, $RefreshNotice } from './styles'
import { useAppSelector } from 'store/hooks'
import { RootState } from 'store'

const COLORS = [
  '#E57373',
  '#9575CD',
  '#81C784',
  '#6a9af1',
  '#FF8A65',
  '#F06292',
  '#7986CB',
]

export const MultiUserFeedback: React.FC = () => {
  const others = useOthers()
  const status = useStatus()
  const editorSelector = useAppSelector((state: RootState) => state.calls.editorSelector)
  const [userEditor, setUserEditor] = React.useState('')
  const updateMyPresence = useUpdateMyPresence()

  const broadcast = useBroadcastEvent()

  React.useEffect(() => {
    const onBroadCastRefresh= () => {
      broadcast({ type: 'REFRESH' })
    }
    document.body.addEventListener('call-refresh', onBroadCastRefresh)
    return () => document.body.removeEventListener('call-refresh', onBroadCastRefresh)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broadcast])


  React.useEffect(() => {
    updateMyPresence({ editorSelector })
  }, [editorSelector, updateMyPresence])

  useEventListener(({ event, user }) => {
    if (event.type === 'REFRESH') {
      setUserEditor(user?.presence?.userId || '')
    }
  })

  return (
    <$MultiUserFeedback>
      {/* Show cursors of other users */}
      {/* {others.map(({ connectionId, presence }) => {
        if (presence.cursor === null) {
          return null
        }
        return (
          <div key={`cursor-${connectionId}`}>
            <$Cursor
              x={presence.cursor.x}
              y={presence.cursor.y}
              width='24'
              height='36'
              viewBox='0 0 24 36'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M5.65376 12.3673H5.46026L5.31717 12.4976L0.500002 16.8829L0.500002 1.19841L11.7841 12.3673H5.65376Z'
                fill={COLORS[connectionId % COLORS.length]}
              />
            </$Cursor>
            <$Name
              color={COLORS[connectionId % COLORS.length]}
              x={presence.cursor.x}
              y={presence.cursor.y}
            >
              {`Step ${(presence.editorSelector?.step || 0) + 1} > Form ${(presence.editorSelector?.form || 0) + 1}`}
            </$Name>
          </div>
        )
      })} */}
      {status == 'connected' && <$OnlineUsers>
        {others.map(({ connectionId, presence }) => <$Chip
          key={presence.userId}
          bgColor={COLORS[connectionId % COLORS.length]}
          label={<>
            <div>{presence.userId || ''}</div>
            <div>{`Step ${(presence.editorSelector?.step || 0) + 1} > Form ${(presence.editorSelector?.form || 0) + 1}`}</div>
          </>
          } />,
        )}
      </$OnlineUsers>}
      {userEditor && <$RefreshNotice label={`New changes by ${userEditor}, please refresh.`} />}
    </$MultiUserFeedback>
  )
}
