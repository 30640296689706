import styled from 'styled-components'
import { NewButton } from 'prace-common-components'
import Grid from '@material-ui/core/Grid'

//TODO: See if some of these styles can be merged with others (from EditCallModal)
export const $Button = styled(NewButton)`
  &.MuiButtonBase-root {
    margin-left: auto;
  }
`

export const $CutOffContainer = styled(Grid)`
  gap: ${({ theme }) => theme.spacing(2)};
  &.MuiGrid-container {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }
`

export const $TimeframeContainer = styled(Grid)`
  margin-left: ${({ theme }) => theme.spacing(6)};
  margin-top: ${({ theme }) => theme.spacing(1)};
  gap: ${({ theme }) => theme.spacing(2)};
  &.MuiGrid-container {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: ${({ theme }) => theme.spacing(1)};
  }
`

export const $CutoffName = styled(Grid)<{editingCall?: boolean}>`
  &.MuiGrid-item {
    margin-left: ${({ theme, editingCall }) => editingCall ? theme.spacing(6) : theme.spacing(0)};
  }
`

export const $RemoveCutoff = styled(Grid)`
  &.MuiGrid-item {
    margin-top: ${({ theme }) => theme.spacing(0.8)};
  }
`

export const $StepTitle = styled.div`
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
