import { CDN, PORTAL } from 'constants/global'
import { clientApi } from '..'
import {
  LoginPayload,
  LoginResponse,
  ResetPayload,
  InvitePayload,
} from './types'
import { User, withLoading } from 'prace-common-components'

export const authApi = clientApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<LoginResponse, LoginPayload>({
      query: (body) => ({
        url: '/users/login',
        credentials: 'include',
        method: 'POST',
        body: { ...body, app: PORTAL },
      }),
      transformResponse: (res: LoginResponse, _meta, args) => ({ ...res, email: args.email }),
    }),
    refresh: build.mutation<LoginResponse, void>({
      query: () => ({
        url: '/users/refresh',
        method: 'POST',
        credentials: 'include',
        body: { app: PORTAL },
      }),
    }),
    validate: build.mutation<void, { email: string; token: string }>({
      query: ({ token, ...body }) => ({
        url: '/users/register/confirmation',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body,
      }),
    }),
    forgot: build.mutation<void, { email: string }>({
      query: (body) => ({
        url: '/users/forgot',
        method: 'POST',
        body,
      }),
    }),
    reset: build.mutation<void, ResetPayload>({
      query: ({ token, ...body }) => ({
        url: '/users/reset',
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
        },
        body,
      }),
    }),
    logout: build.mutation<void, void>({
      query: () => ({
        url: '/users/logout',
        credentials: 'include',
        method: 'POST',
        body: { app: PORTAL },
      }),
    }),
    invite: build.mutation<void, InvitePayload>({
      query: (body) => ({
        url: '/users/invite',
        method: 'POST',
        body,
      }),
    }),
    getTheme: build.query<any, void>({
      keepUnusedDataFor: 432000, // 5 days
      query: () => ({ url: `${CDN}/theme.json` }),
    }),
    confirmEmail: build.mutation<void, { email: string; token: string }>({
      ...withLoading,
      query: ({ token, ...body }) => ({
        url: '/users/admin/confirmation',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body,
      }),
    }),
    getAdmins: build.query<User[], void>({
      ...withLoading,
      query: () => ({ url: '/users/admins' }),
    }),
    inviteAdmin: build.mutation<void, { email: string }>({
      ...withLoading,
      query: (body) => ({
        url: '/users/invite/admin',
        method: 'POST',
        body,
      }),
    }),
    revokeAdmin: build.mutation<void, { email: string }>({
      ...withLoading,
      query: (body) => ({
        url: '/users/revoke/admin',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useLoginMutation,
  useLogoutMutation,
  useForgotMutation,
  useResetMutation,
  useRefreshMutation,
  useValidateMutation,
  useInviteMutation,
  useGetThemeQuery,
  useConfirmEmailMutation,
  useGetAdminsQuery,
  useInviteAdminMutation,
  useRevokeAdminMutation,
} = authApi
