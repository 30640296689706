import * as React from 'react'
import { ELEMENT_BASE_PARAMS, FlowStep, Permissions, ElementOptions, Divider, ELEMENT_TYPES, ElementValue, ConfirmAction, NotificationType } from 'prace-common-components'
import Grid from '@material-ui/core/Grid'
import { MinMaxConfig } from './MinMaxConfig'
import { ElementPermission } from 'components/ElementPermission'
import { $DeleteButton } from './styles'
import { useAppDispatch } from 'store/hooks'
import { useDeleteFlowElementsMutation, useUpdateElementMutation } from 'store/api/calls/elements'

export const EditStep: React.FC<{step: FlowStep}> = ({ step }) => {
  const dispatch = useAppDispatch()
  const [updateElement] = useUpdateElementMutation()
  const [deleteElement] = useDeleteFlowElementsMutation()
  const { config, permissions = [], id, order } = step

  const onChangeOption = async (name: string, value: ElementValue) => {
    try {
      const newValues = ELEMENT_BASE_PARAMS.includes(name)
        ? { id: step.id, [name]: value }
        : { id: step.id, config: { ...step.config, [name]: value }}
      await updateElement(newValues).unwrap()
      dispatch({ type: 'updateStep', payload: { newValues } })
    } catch (err) {
      console.log(err)
    }
  }

  const onChangeElementPermission = async (name: string, value: ElementValue) => {
    try {
      const newValues = {id: step.id, permissions: {...permissions, [name]: value } as Permissions}
      await updateElement(newValues).unwrap()
      dispatch({ type: 'updateStep', payload: { newValues } })
    } catch (err) {
      console.log(err)
    }
  }

  const handleDelete = async () => {
    try {
      await deleteElement({ ids: [id] }).unwrap()
      dispatch({ type: 'deleteStep', payload: { stepId: id } })
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Step deleted' } })
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Grid container>
      <ElementOptions element={step} onChange={onChangeOption} />
      <Divider />
      <ElementPermission
        permissions={permissions as Permissions}
        type={ELEMENT_TYPES.STEP}
        step={order}
        id={id}
        onChange={onChangeElementPermission}
      />
      <Divider />
      <MinMaxConfig
        title='Accepted Assignments'
        minName='minAssignments'
        maxName='maxAssignments'
        min={config?.minAssignments || null}
        max={config?.maxAssignments || null}
        onChange={onChangeOption}
      />
      <Divider />
      <ConfirmAction deletion title='Delete step' description='Deleting this step will also delete all forms and elements inside.' onConfirm={handleDelete}>
        <$DeleteButton error onClick={handleDelete}>Delete Step</$DeleteButton>
      </ConfirmAction>
    </Grid>
  )
}
