import * as React from 'react'
import update from 'immutability-helper'
import { ConditionEdit, ConditionEditorProps } from './types'
import { FlowFormElement, CallPartitionResource, Item, getFlowElement, ElementsFlow, EFFECTS, VALIDATION_TYPES, FORM_ELEMENT_TYPES, Select, Input, ConfirmAction, NewButton, SvgIcon, Condition, NotificationType } from 'prace-common-components'
import useElementSelects from 'util/useElementSelects'
import { $ButtonContainer, $ColumnContainer, $ConditionLabel, $Grid, $ModalContainer } from 'components/FormModal/styles'
import { FlowElementIds, FormElement } from 'components/FormModal/types'
import Grid from '@material-ui/core/Grid'
import { $AddedElementsContainer, $ElementItem, $ActionsContainer, $Summary, $ElementSpan, $TitleSpan } from './styles'
import { RootState } from 'store'
import { useAppDispatch, useAppSelector } from 'store/hooks'

const emptyFlowElement= { step: null, form: null, element: null, groupElement: null }
const ElementParams = ['step', 'form', 'element', 'groupElement']
const emptyCondition = {
  effect: null,
  validation: {
    type: null,
    values: [],
    elements: [],
  },
} as ConditionEdit

export const ConditionEditor: React.FC<ConditionEditorProps> =
({currentCondition = emptyCondition, flowElement, onSubmit, optionGroups, insideGroup, loading },
) => {
  const dispatch = useAppDispatch()
  const [selectValues, setSelectValues] = React.useState<Condition | ConditionEdit>({
    ...currentCondition,
  })
  const [editFlowElement, setEditFlowElement] = React.useState<FlowElementIds>(emptyFlowElement)
  const { effect, validation } = selectValues
  const { type, values, elements } = validation
  const elementsLength = elements.length
  const [showGroupElement, setShowGroupElement] = React.useState<FlowFormElement['id'] | undefined>(undefined)
  const [editElementIdx, setEditElementIdx] = React.useState<number>(elementsLength)
  const call = useAppSelector((state: RootState) => state.calls.call)
  /* You can only select groupelements from the same group */
  const canSelectGroupElements = showGroupElement ? insideGroup && (insideGroup === showGroupElement) : true
  const [updatedEditFlowElement, elementSelects] = useElementSelects(
    call.flow,
    editFlowElement,
    'For which flow element(s) will this condition be?',
    false,
    false,
    canSelectGroupElements ? !!showGroupElement : false,
  )

  const nonValueTypes = (type === VALIDATION_TYPES.REQUIRED) || (type === VALIDATION_TYPES.NON_EMPTY)

  let allResources: CallPartitionResource[] = []
  call.cutoffs.forEach((cutOff) => {
    allResources = [...allResources, ...cutOff.resources]
  })

  /* If the cutoff is not active, it will not show to the users (applications) */
  const partitionIds = [...new Set(allResources.map((resource) => resource?.partition?.id))] || []
  const partitionOptions: Item[] = partitionIds.map((id) => {
    const partitionName = allResources.find((resource) => resource.partition?.id == id)?.partition?.name || ''
    return { value: id, label: partitionName }
  }) || []
  /* Sort partitions by name */
  const sortedPartitionOptions = partitionOptions?.sort((a, b) => {
    if (a.label < b.label) return -1
    if (a.label > b.label) return 1
    return 0
  }) || []

  const conditionTypes = React.useMemo(() => {
    if(!effect || effect === EFFECTS.SUM) return []
    return [
      { id: 3, name: 'type', value: type,
        elementType: FORM_ELEMENT_TYPES.SELECT,
        title: 'What condition triggers the action?',
        items: Object.values(VALIDATION_TYPES).filter((type) => type !== VALIDATION_TYPES.UNCONDITIONAL)
          .map((c) => { return { value: c, label: c } }),
        options: {
          placeholder: 'Choose a condition',
        },
      },
    ]
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, effect])

  let foundSelect: Nullable<FlowFormElement> = null
  let foundCheckBox: Nullable<FlowFormElement> = null
  elements.forEach((element: { id: number; elementType?: FORM_ELEMENT_TYPES }) => {
    const flowElement = getFlowElement(call, element.id).element
    const type = flowElement?.elementType
    if(type == FORM_ELEMENT_TYPES.SELECT ||
      type == FORM_ELEMENT_TYPES.MULTISELECT ||
      type == FORM_ELEMENT_TYPES.TOGGLE ||
      type == FORM_ELEMENT_TYPES.SELECT_PARTITION)
      foundSelect = flowElement
    if(type == FORM_ELEMENT_TYPES.CHECKBOX)
      foundCheckBox = flowElement
  })

  const conditionValues = React.useMemo(() => {
    if(!effect || effect === EFFECTS.SUM || nonValueTypes)
      return []
    const textValue = type === VALIDATION_TYPES.EQUAL || type === VALIDATION_TYPES.NOT_EQUAL
    const elementType = (foundSelect || foundCheckBox) ? FORM_ELEMENT_TYPES.SELECT :
      textValue ? FORM_ELEMENT_TYPES.TEXT : FORM_ELEMENT_TYPES.NUMBER
    const partitionSelect = foundSelect?.elementType == FORM_ELEMENT_TYPES.SELECT_PARTITION
    return type === VALIDATION_TYPES.LENGTH_RANGE ? [{
      id: 4, name: 'values1', value: values ? values![0] : null,
      elementType: FORM_ELEMENT_TYPES.NUMBER,
      title: '',
      options: {
        placeholder: 'Minimum value',
      },
    }, {
      id: 5, name: 'values2', value: values && values.length > 1 ? values![1] : null,
      elementType: FORM_ELEMENT_TYPES.NUMBER,
      title: '',
      options: {
        placeholder: 'Maximum value',
      },
    }] : [{
      id: 6, name: 'values', value: values ? values![0] : null,
      elementType,
      items: foundCheckBox ? [{value: 'true', label: 'True'}, {value: 'false', label: 'False'}] :
        partitionSelect ? sortedPartitionOptions :
          foundSelect ? (foundSelect.optionsGroup?.options ||
          optionGroups.find((optGroup) => optGroup.tag === foundSelect?.optionsGroup?.tag)?.options || []) : [],
      title: '',
      options: {
        placeholder: 'Choose response',
      },
    }]
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, effect, foundSelect, sortedPartitionOptions, foundCheckBox])

  const conditions = [...conditionTypes, ...conditionValues]
  const notEditingElement = editElementIdx === elementsLength

  const handleChange = (name: string, value: Nullable<StringNumber | EFFECTS>) => {
    if(ElementParams.includes(name)) {
      let isGroupElement = false
      if(name === 'element') {
        const flowElement = getFlowElement(call, Number(value))
        const element = flowElement.element
        /* Group element selected */
        isGroupElement = !!(element && element.flow.length)
        /* Show select for elements inside group */
        setShowGroupElement(isGroupElement ? element?.id : undefined)
      }
      const newEditFlowElement = update(updatedEditFlowElement as FlowElementIds, {
        [name] : { $set: value },
      })
      if(((name === 'element' && !isGroupElement) || name === 'groupElement') && notEditingElement) addElement(newEditFlowElement)
      else setEditFlowElement(newEditFlowElement)
    } else {
      if(name === 'effect') {
        const sumValue = value === EFFECTS.SUM
        /* Only allow sum to be selected for elements of type number */
        if(sumValue && flowElement?.elementType !== FORM_ELEMENT_TYPES.NUMBER) {
          dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Only elements of type number can have a sum condition' } })
          return
        }
        setSelectValues((prevState: Condition | ConditionEdit) => ({
          ...prevState, effect: value as EFFECTS, validation: {
            ...prevState.validation,
            type: sumValue ? null : prevState.validation.type,
            values: sumValue ? [] : prevState.validation.values,
          }}))
      } else {
        if(name.includes('values')) {
          setSelectValues((prevState: Condition | ConditionEdit) => {
            const prevValues = prevState.validation.values
            let values: string[] = []
            if(name === 'values1') values = value ? prevValues && prevValues.length > 1 ? [value as string, prevValues![1] as string] : [value as string] : []
            else if(name === 'values2') values = value ? [prevValues![0] as string, value as string] : [prevValues![0] as string]
            if(name === 'values') values = value ? [value as string] : []
            return {...prevState, validation: {
              ...prevState.validation,
              values,
            }}})
        } else {
          if(name === 'type') {
            setSelectValues((prevState: Condition | ConditionEdit) => ({
              ...prevState, validation: {
                ...prevState.validation,
                values: [],
                type: value as VALIDATION_TYPES,
              }}))
          } else {
            setSelectValues((prevState: Condition | ConditionEdit) => ({
              ...prevState, validation: {
                ...prevState.validation,
                [name]: value,
              }}))
          }
        }
      }
    }
  }

  const validElement = (editFlowElement?: FlowElementIds) => {
    const editingElement = editFlowElement ? editFlowElement : updatedEditFlowElement as FlowElementIds
    if(showGroupElement && !(editingElement as FlowElementIds).groupElement) return false
    else if(!(editingElement as FlowElementIds).element) return false
    const { element, groupElement } = editingElement as FlowElementIds
    /* Cant use same element */
    if(flowElement.id == element || flowElement.id == groupElement) {
      dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Cannot add the element itself' } })
      return false
    }
    /* Cant have duplicate elements */
    const foundElement = elements.find((addedEl: {id: number}, i: number) =>
      (addedEl.id == element || addedEl.id == groupElement) && (editElementIdx !== i))
    if(foundElement) {
      dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Cannot add duplicates' } })
    }
    return !foundElement
  }

  const addElement = (editFlowElement?: FlowElementIds) => {
    const editingElement = editFlowElement ? editFlowElement : updatedEditFlowElement as FlowElementIds
    if(!validElement(editFlowElement)) return
    const { element, groupElement } = editingElement
    setSelectValues((prevState: Condition | ConditionEdit) => update(prevState, {
      validation: {
        elements: { $push: [{ id: groupElement ? groupElement as number : element as number}] },
      },
    }))
    setEditFlowElement(emptyFlowElement)
    setShowGroupElement(undefined)
    setEditElementIdx((prevIdx) => prevIdx + 1)
  }

  const editElement = (idx: number) => {
    setEditElementIdx(idx)
    const {step, form, element, groupElement} = getFlowElement(call,  elements[idx].id)
    const editElement = {
      step: step ? step.id : null,
      form: form ? form.id : null,
      element: groupElement ? groupElement.id : (element?.id || null),
      groupElement: groupElement ? (element?.id || null) : null,
    }
    setShowGroupElement(groupElement?.id)
    setEditFlowElement(editElement)
  }

  const handleSubmit = () => {
    const {validation, effect} = selectValues
    const { type, values, elements } = validation
    const isSum = effect === EFFECTS.SUM
    /* if(isSum) { // Only allows sums with number inputs
      const allNumbers = elements.every((element: { id: number }) =>
        getFlowElement(call,  element.id).element?.elementType === FORM_ELEMENT_TYPES.NUMBER)
      if(flowElement.elementType !== FORM_ELEMENT_TYPES.NUMBER || !allNumbers) {
        dispatch(NOTIFICATION_EVENTS.OPEN_NOTIFICATION,
          { notificationType: 'error', msg: 'Can only create sums between number inputs', timer: 3000 })
        return
      }
    } */
    if(!isSum && (!values || !effect || !type || !elements.length)) {
      dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Please fill all required data' } })
      return
    }
    if(isSum && elements.length < 2) {
      dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Sum conditions need at least 2 elements' } })
      return
    }
    onSubmit({
      id: currentCondition.id || 0,
      order: currentCondition.order || 0,
      effect,
      validation: {
        order: currentCondition.validation.order || 0,
        type: isSum ? VALIDATION_TYPES.UNCONDITIONAL : type as VALIDATION_TYPES,
        elements,
        values: values || [],
      },
    })
  }

  const handleEdit = () => {
    if(!validElement()) return
    const { element, groupElement } = updatedEditFlowElement as FlowElementIds
    setSelectValues((prevState: Condition | ConditionEdit) => update(prevState, {
      validation: {
        elements: {
          [editElementIdx]: { $set: {id: groupElement ? groupElement as number : element as number} },
        },
      },
    }))
    setEditFlowElement(emptyFlowElement)
    setShowGroupElement(undefined)
    setEditElementIdx(elementsLength)
  }

  const handleDelete = () => {
    setEditElementIdx(elementsLength - 1)
    setSelectValues((prevState: Condition | ConditionEdit) => update(prevState, {
      validation: {
        elements: { $splice: [[editElementIdx, 1]] },
      },
    }))
    setEditFlowElement(emptyFlowElement)
  }

  const addedElements: ElementsFlow[] = elements.map(
    (insertedElement: {id: number; elementType?: FORM_ELEMENT_TYPES}) => getFlowElement(call, insertedElement.id),
  )

  const summary = () => {
    const isSum = effect === EFFECTS.SUM
    if(!isSum && (!type || !effect || !elements.length)) return ''

    const elementNames = elements.map((flowElement: {id: number; elementType?: FORM_ELEMENT_TYPES}) => {
      const { element } = getFlowElement(call, flowElement.id)
      return `${element?.title}`
    })
    const singleElement = elements.length < 2
    const subTitle = singleElement ?
      `${elementNames[0]}` : `the ${elements.length} elements`

    let typeText = ''
    switch(type) {
      case VALIDATION_TYPES.EQUAL:
      case VALIDATION_TYPES.NOT_EQUAL: {
        typeText = ` ${singleElement ? 'is' : 'are'} ${type} to`
        break
      }
      /* case VALIDATION_TYPES.MIN:
      case VALIDATION_TYPES.MAX: */
      case VALIDATION_TYPES.MIN_LENGTH:
      case VALIDATION_TYPES.MAX_LENGTH:{
        typeText = ` ${singleElement ? 'has' : 'have'} a ${type} of`
        break
      }
      case VALIDATION_TYPES.LENGTH_RANGE: {
        typeText = ` ${singleElement ? 'has' : 'have'} a value in the range`
        break
      }
      case VALIDATION_TYPES.REQUIRED: {
        typeText = ` ${singleElement ? 'is' : 'are'} required`
        break
      }
      case VALIDATION_TYPES.NON_EMPTY: {
        typeText = ` ${singleElement ? 'is' : 'are'} not empty`
        break
      }
      case VALIDATION_TYPES.GREATER:
      case VALIDATION_TYPES.LESSER: {
        typeText =  ` ${singleElement ? 'is' : 'are'} ${type} than`
        break
      }
      default:
        break
    }

    const partitionValue = singleElement && values && elements[0]?.elementType == FORM_ELEMENT_TYPES.SELECT_PARTITION ?
      sortedPartitionOptions.find((partition) => partition.value == values[0])?.label : undefined

    if(isSum) {
      if(singleElement) return ''
      return <$Summary>
        The element <$ElementSpan>{`${flowElement.title} `}</$ElementSpan>
          is the <$TitleSpan>SUM</$TitleSpan> of <$ElementSpan>{subTitle}</$ElementSpan>.
      </$Summary>
    }
    return <$Summary>
      <$TitleSpan>IF</$TitleSpan> <$ElementSpan>{subTitle}</$ElementSpan>{typeText}{values?.length ? values.length > 1 ? ` "[${values[0]}, ${values[1]}]"` : ` "${partitionValue ? partitionValue : values[0]}"` : ''}, <$TitleSpan>THEN</$TitleSpan> <$ElementSpan>{flowElement.title}</$ElementSpan> is {effect}.
    </$Summary>
  }

  /* Not all elements can respect the condition trigger */
  const possibleValues = conditionValues.length ?
    (conditionValues[0] as { items: Item[] })?.items?.map((v) => v.value) : []
  const selectsError = foundSelect && !nonValueTypes && effect !== EFFECTS.SUM ? addedElements?.some((addedEl) =>
    !!addedEl.element?.optionsGroup?.options?.find((o) => !possibleValues.includes(o.value))) : false

  return (
    <$ModalContainer width={900}>
      <$Grid container spacing={4}>
        <$ColumnContainer item xs={6}>
          <$ConditionLabel>IF</$ConditionLabel>
          <$AddedElementsContainer>
            {addedElements.map((addedElement: ElementsFlow, i: number) =>
              addedElement.step ?
                <Grid key={i} container alignItems='center' onClick={() => editElement(i)}>
                  <$ElementItem>
                    {addedElement.groupElement ?
                      `${i + 1}. Group ${addedElement.groupElement?.title} > ${addedElement.element?.title}`
                      :
                      `${i + 1}. ${addedElement.step?.title} > ${addedElement.form?.title} > ${addedElement.element?.title}`
                    }
                  </$ElementItem>
                  <SvgIcon name='altEdit' clickable size={16} />
                </Grid>
                : null,
            )}
          </$AddedElementsContainer>
          {(elementSelects as FormElement[]).map((select: FormElement, idx: number) => <Select
            key={select.id}
            name={select.name}
            value={select.value as string}
            items={select.items || []}
            {...select.options}
            error={idx == 2 && !canSelectGroupElements}
            errorMsg={idx == 2 && !canSelectGroupElements ? 'You cannot make conditionals from external group elements' : ''}
            onChange={handleChange}
          />)}
          {notEditingElement ?
            <Grid container>
              <NewButton onClick={addElement} creator variant='outlined'>Add Flow element</NewButton>
            </Grid>
            :
            <$ActionsContainer container>
              <ConfirmAction deletion title='Remove Flow element' description='This action will remove the flow element from the current condition.' onConfirm={handleDelete}>
                <NewButton onClick={handleDelete} error variant='outlined'>Remove element</NewButton>
              </ConfirmAction>
              <NewButton onClick={handleEdit} variant='outlined'>Edit element</NewButton>
            </$ActionsContainer>
          }
          {conditions.map((conditionElement: FormElement, idx) =>
            conditionElement.elementType === FORM_ELEMENT_TYPES.SELECT ?
              <Select
                key={conditionElement.id}
                name={conditionElement.name}
                title={conditionElement.title || ''}
                value={conditionElement.value as string}
                disabled={!notEditingElement}
                items={conditionElement.items || []}
                error={!!idx && selectsError}
                errorMsg={idx && selectsError ? `The value "${conditionElement.value}" is not possible for every element selected` : undefined}
                {...conditionElement.options}
                onChange={handleChange}
              />
              :
              <Input
                key={conditionElement.id}
                name={conditionElement.name}
                value={conditionElement.value as string}
                title={conditionElement.title || ''}
                disabled={!notEditingElement || selectsError}
                error={!!idx && selectsError}
                errorMsg={idx && selectsError ? `The value "${conditionElement.value}" is not possible for every element selected` : undefined}
                type={conditionElement.elementType}
                hideItalic
                {...conditionElement.options}
                onChange={handleChange}
              />,
          )}
        </$ColumnContainer>
        <$ColumnContainer divider item xs={6}>
          <$ConditionLabel>THEN</$ConditionLabel>
          <Select
            name='effect'
            value={effect}
            items={Object.values(EFFECTS).map((a) => { return { value: a, label: a } })}
            title='What happens if the condition is met?'
            disabled={!notEditingElement}
            placeholder='Choose an effect'
            required
            onChange={handleChange}
          />
          {summary()}
        </$ColumnContainer>
      </$Grid>
      <$ButtonContainer container justifyContent='flex-end'>
        <NewButton
          loading={loading} 
          disabled={!notEditingElement || selectsError || !canSelectGroupElements}
          onClick={handleSubmit}
        >
            Save
        </NewButton>
      </$ButtonContainer>
    </$ModalContainer>
  )
}
