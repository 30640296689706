import * as React from 'react'
import update from 'immutability-helper'
import Grid from '@material-ui/core/Grid'
import { ActionsProps, ACTION } from './types'
import { getElementName, NewLoading as Loading, ElementPath, newTheme, ActionCondition, VALIDATION_TYPES, Tooltip, SvgIcon, NestedMenu, getFlowElement, ConfirmModal, ACTION_TARGET_TYPES, ACTION_TARGET_NAMES, ActionTarget, Automation, ActionContext, ACTIONS, ACTION_NAMES, CardElement, Title, NewModal, CardButton, Sortable, OnMove, NotificationType, ACTION_EVENTS } from 'prace-common-components'
import { $Grid, $SvgIcon, $ActionContainer, $ConditionIcons, $WarningGrid, $Warning } from './styles'
import { ActionsModal } from 'components/ActionsModal/ActionsModal'
import { EditAction, EditContext } from 'components/ActionsModal/types'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { RootState } from 'store'
import { useAddAutomationMutation, useDeleteAutomationMutation, useUpdateElementMutation } from 'store/api/calls/elements'
import { getActionSubTitle } from 'util/getActionSubTitle'
import { Routes } from 'constants/global'
import { Link } from 'react-router-dom'
import { anyActionError } from 'util/validateEditCall'

type ElementFullPath = ElementPath & { groupElement: Nullable<number> }

const menuItems = [
  {id: 1, label: <><$SvgIcon name='altEdit' size={16} />Edit</>},
  {id: 2, label: <><$SvgIcon name='delete' size={16} />Delete</>},
]

export const Actions: React.FC<ActionsProps> = ({ step }) => {
  const dispatch = useAppDispatch()
  const call = useAppSelector((state: RootState) => state.calls.call)
  const templates = useAppSelector((state: RootState) => state.calls.templates)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [selectedId, setSelectedId] = React.useState<number>()
  const [editAction, setEditAction] = React.useState<EditAction>()
  const [openDelete, setOpenDelete] = React.useState<boolean>(false)
  const [actionType, setActionType] = React.useState<Nullable<ACTIONS>>(null)
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [actions, setActions] = React.useState(step.automations)
  const [deleteAutomation] = useDeleteAutomationMutation()
  const [addAutomation] = useAddAutomationMutation()
  const [updateElement] = useUpdateElementMutation()

  //FIXME: Implement onBackClick
  const onBackClick = () => null

  const allAutomations: Automation[] = []
  call.flow.forEach((s) => allAutomations.push(...s.automations))
  const targetingAssignActions = allAutomations.filter(
    (a) => a.type == ACTIONS.ASSIGN && 
      [ACTION_EVENTS.ASSIGNED, ACTION_EVENTS.LEAD_ASSIGNED, ACTION_EVENTS.NON_LEAD_ASSIGNED].includes(a.event) &&
     ((a.context as ActionContext[ACTIONS.ASSIGN])?.flowElement?.id == step?.id),
  ) || []

  const handleAddAction = (type: ACTIONS) => {
    setActionType(type)
    setOpen(true)
  }

  React.useEffect(() => setActions(step.automations), [step.automations]) 

  const onMove = ({
    dragIndex,
    hoverIndex,
    dragType,
  }: OnMove) => {
    if(dragType === ACTION)
      setActions((prevAutomations) => {
        const newAutomations = update(prevAutomations, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevAutomations[dragIndex]],
          ],
        }).map((automation: Automation, i: number) => ({ ...automation, order: i+1 }))
        return newAutomations
      })
  }

  const handleClick = (e: React.MouseEvent<any>, id: number) => {
    setAnchorEl(e.currentTarget)
    setSelectedId(Number(id))
  }

  const handleItemClick = (id: StringNumber) => {
    setAnchorEl(null)
    if(selectedId === undefined) return
    switch(id) {
      case 1: {
        const automation = actions.find((action: Automation) => action.id === selectedId)
        handleEditAction(automation!)
        break
      }
      default:
        setOpenDelete(true)
    }
  }

  const handleEditAction = (action: Automation) => {

    const emptyElementPath = { step: null, form: null, element: null, groupElement: null }
    const { context, targets = [] } = action

    let editFlowElement: ElementFullPath = emptyElementPath
    if((context as {flowElement: { id: number }}).flowElement) {
      const elementPath = getFlowElement(call, (context as {flowElement: { id: number }}).flowElement.id)
      editFlowElement = {
        step: elementPath.step?.id || null,
        form: elementPath.form?.id || null,
        element: elementPath.groupElement ? elementPath.groupElement?.id || null : elementPath.element?.id || null,
        groupElement: elementPath.groupElement ? elementPath.element?.id || null : null,
      }
    }

    const editTargets = targets.map((target) => {
      let flowElement: ElementFullPath = emptyElementPath
      if(target.targetElement) {
        const elementPath = getFlowElement(call, target.targetElement.id)
        flowElement = {
          step: elementPath.step?.id || null,
          form: elementPath.form?.id || null,
          element: elementPath.groupElement ? elementPath.groupElement?.id || null : elementPath.element?.id || null,
          groupElement: elementPath.groupElement ? elementPath.element?.id || null : null,
        }
      }
      return {
        ...target,
        flowElement,
      }})
    setEditAction({
      ...action,
      context: {
        ...context,
        flowElement: editFlowElement,
      } as EditContext,
      targets: editTargets,
      reminder: {}, //TODO: Define reminder better when BE ready
    } as EditAction)

  }

  const handleDelete = async () => {
    try {
      if(selectedId === undefined) return
      await deleteAutomation({ stepId: step.id, id: selectedId }).unwrap()
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Action deleted' } })
      setOpenDelete(false)
    } catch (err) {
      console.log(err)
    }
  }

  const onEditAction = async (type: ACTIONS, data: Omit<Automation, 'id' | 'order' | 'type'>) => {
    try {
      setLoading(true)
      const automation = {
        ...editAction,
        type,
        id: editAction!.id || 0,
        order: editAction!.order || 0,
        ...data,
        condition: data.condition ? data.condition : { conditionalElement: { id: null }, values: [], type: null },
      }
      const apiStep = await updateElement({ id: step.id, automations: [automation] }).unwrap()
      dispatch({ type: 'updateAutomations', payload: { stepId: step.id, automations: apiStep.automations } })
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Action updated' } })
      setEditAction(undefined)
      setActionType(null)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }

  }

  const onEndDrag = async () => {
    try {
      const automationsOrders = actions.map((automation: Automation, i: number) =>
        ({id: automation.id, order: i+1}) as Automation)
      const apiStep = await updateElement({ id: step.id, automations: automationsOrders }).unwrap()
      const automations = [...apiStep.automations].sort((a, b) => a.order - b.order)
      dispatch({ type: 'updateAutomations', payload: { stepId: step.id, automations } })
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Actions updated' } })
    } catch (err) {
      dispatch({ type: 'updateAutomations', payload: { stepId: step.id } })
      console.log(err)
    }
  }

  const onAddAction = async (type: ACTIONS, data: Omit<Automation, 'id' | 'order' | 'type'>) => {
    try {
      const automation = { order: actions.length+1, type, ...data, id: undefined } as unknown as Automation
      await addAutomation({ id: step.id, automation }).unwrap()
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Action added' } })
      setOpen(false)
      setActionType(null)
    } catch (err) {
      console.log(err)
    }
  }

  const getTargetNames = (targets: ActionTarget[]) => {
    return targets.map((target) => {
      if(target.targetType === ACTION_TARGET_TYPES.FLOW_ELEMENT_EMAIL) {
        return getElementName(target.targetElement.id, call)
      } else {
        return ACTION_TARGET_NAMES[target.targetType]
      }
    })
  }

  const conditionText = (cond: ActionCondition): string => {
    const { conditionalElement, type, values } = cond
    if(!values) return ''
    const condValues = Array.isArray(values) ? values : [values]
    const { element } = getFlowElement(call, (conditionalElement as {id: Nullable<number>}).id)
    const valuesLength = condValues.length
    const validConditionType = type && (type !== VALIDATION_TYPES.UNCONDITIONAL)
    const conditionTypeText = type === VALIDATION_TYPES.REQUIRED ?
      ` > ${type}` :
      ` > ${type} ${valuesLength > 1 ?`> [${condValues[0]}, ${condValues[1]}]` : valuesLength ? `> ${condValues[0]}` : ''}`

    return `${element?.title}` + (validConditionType ? conditionTypeText : '')
  }

  return (
    <>
      <Loading loading={loading} />
      <$Grid container alignItems='center'>
        <Title
          hasBack
          onBackClick={onBackClick}
        >
          Actions
        </Title>
        <Title subTitle>
          Select Action Type
        </Title>
        <$Grid container spacing={2}>
          <Grid item xs>
            <CardButton
              alternative
              title='Assign'
              icon={ACTIONS.ASSIGN}
              selected={actionType === ACTIONS.ASSIGN}
              onClick={() => handleAddAction(ACTIONS.ASSIGN)}
            />
          </Grid>
          <Grid item xs>
            <CardButton
              alternative
              title='Visibility'
              icon={ACTIONS.VISIBILITY}
              selected={actionType === ACTIONS.VISIBILITY}
              onClick={() => handleAddAction(ACTIONS.VISIBILITY)}
            />
          </Grid>
          <Grid item xs>
            <CardButton
              alternative
              title='Notify'
              icon={ACTIONS.NOTIFY}
              selected={actionType === ACTIONS.NOTIFY}
              onClick={() => handleAddAction(ACTIONS.NOTIFY)}
            />
          </Grid>
        </$Grid>
        {actions.length ?
          <>
            <Title subTitle>
            Action order
            </Title>
            <Grid container>
              {!!anyActionError(actions, templates) && <$WarningGrid container>
                <SvgIcon name='warning-outlined' color={newTheme.colors.feedback.error} size={20} />
                <$Warning>There are actions with errors</$Warning>
              </$WarningGrid>}
              {actions.map((action, idx: number) => <$ActionContainer xs={12} key={action.id} item>
                <$ConditionIcons>
                  {!!action.roles?.length && <Tooltip
                    placement='top'
                    show
                    text={action.roles.join(' | ')}
                  >
                    <div>
                      <SvgIcon color='primary' name='account' size='20' />
                    </div>
                  </Tooltip>}
                  {!!(action.condition?.conditionalElement as { id?: number })?.id && <Tooltip
                    placement='top'
                    show
                    text={conditionText(action.condition!)}
                  >
                    <div>
                      <SvgIcon color='primary' name='valid' size='20' />
                    </div>
                  </Tooltip>}
                </$ConditionIcons>
                <Sortable
                  item={{ id: action.id, type: action.type, order: idx }}
                  type={ACTION}
                  dropType={[ACTION]}
                  onMove={onMove}
                  onEndDrag={onEndDrag}
                  alternate
                >
                  <CardElement
                    title={ACTION_NAMES[action.type]}
                    subTitle={action.type === ACTIONS.NOTIFY
                      ? <Link target='_blank' rel='noopener noreferrer' to={Routes.TEMPLATE((action.context as ActionContext[ACTIONS.NOTIFY])?.notificationId)}>{getActionSubTitle(action, templates, call, !step.order)}</Link>
                      : getActionSubTitle(action, templates, call, !step.order)
                    }
                    error={!!anyActionError([action], templates)}
                    //TODO: Add reminder when BE ready
                    subText={/* action.type === ACTIONS.NOTIFY ?
                      (action.data as ActionData[ACTIONS.NOTIFY]).reminder.repeat : */ 'ID: ' + action.id}
                    tooltipText={getTargetNames(action.targets).join('\n')}
                    onActions={(e: React.MouseEvent<SVGSVGElement>) => handleClick(e, action.id as number)}
                    noBorder
                    noPadding />
                </Sortable>
              </$ActionContainer>,
              )}
            </Grid>
          </>
          : null
        }
      </$Grid>
      <NestedMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        items={menuItems}
        handleItemClick={handleItemClick}
      />
      {actionType && open &&
        <NewModal
          open
          alternateTitle
          title={actionType ? ACTION_NAMES[actionType] : ''}
          onClose={() => {
            setOpen(false)
            setActionType(null)
          }}
          notice={actionType === ACTIONS.ASSIGN ? 'Target Role must be on the step\'s assignee permissions' : undefined}
        >
          <ActionsModal
            call={call}
            adminCheck={step.config?.hasAdminCheck}
            templates={templates}
            step={step}
            type={actionType}
            targetingAssignActions={targetingAssignActions}
            onAddAction={onAddAction}
          />
        </NewModal>
      }
      {editAction !== undefined &&
        <NewModal
          open
          alternateTitle
          title={ACTION_NAMES[editAction.type as ACTIONS]}
          onClose={() => {
            setEditAction(undefined)
            setActionType(null)
          }}
          notice={editAction.type === ACTIONS.ASSIGN ? 'Target Role must be on the step\'s assignee permissions' : undefined}
        >
          <ActionsModal
            call={call}
            step={step}
            templates={templates}
            adminCheck={step.config?.hasAdminCheck}
            type={editAction.type as ACTIONS}
            action={editAction}
            targetingAssignActions={targetingAssignActions}
            onAddAction={onEditAction}
          />
        </NewModal>
      }
      {openDelete &&
        <ConfirmModal
          open
          title='Delete Action'
          description='Deleting an action can have consequences on other elements.'
          onConfirm={handleDelete}
          onClose={() => setOpenDelete(false)}
        />
      }
    </>
  )
}

