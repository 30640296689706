import { FlowCall, FlowCutOff, ROLES, withLoading } from 'prace-common-components'
import { clientApi } from '..'
import { CloneCallPayload, CountAppliedResponse, CreateCutOffRolePayload, FlowCallCutoff, UserRole, GetCallCutoffRolesPayload, GetCutoffRoleListsPayload, GetCutoffRoleListsResponse } from './types'
import { EditTimeFrame } from 'components/EditTimeFrames/types'

export const callsApi = clientApi.injectEndpoints({
  endpoints: (build) => ({
    getCalls: build.query<{ data: FlowCall[]; total: number }, { limit: number; offset: number}>({
      ...withLoading,
      query: ({ limit, offset }) => ({ url: `/v2/calls?limit=${limit}&offset=${offset}` }),
    }),
    getCall: build.query<FlowCall, FlowCall['id']>({
      ...withLoading,
      query: (id) => ({ url: `/v2/calls/${id}` }),
    }),
    getCallIds: build.query<{ data: FlowCall[] }, void>({
      ...withLoading,
      query: () => ({ url: '/v2/calls/ids' }),
    }),
    createCall: build.mutation<FlowCall, Partial<FlowCall>>({
      ...withLoading,
      query: (body) => ({
        url: '/v2/calls',
        method: 'POST',
        body,
      }),
    }),
    cloneCall: build.mutation<FlowCall, CloneCallPayload>({
      ...withLoading,
      query: ({ id, ...body}) => ({
        url: `/v2/calls/${id}/clone`,
        method: 'POST',
        body,
      }),
    }),
    updateCall: build.mutation<FlowCall, Partial<FlowCall> & {id: FlowCall['id']; propagateDeadlines?: EditTimeFrame[]}>({
      ...withLoading,
      query: ({ id, ...body}) => ({
        url: `/v2/calls/${id}`,
        method: 'PATCH',
        body,
      }),
    }),
    deleteCall: build.mutation<void, FlowCall['id']>({
      ...withLoading,
      query: (id) => ({
        url: `/v2/calls/${id}`,
        method: 'DELETE',
      }),
    }),
    uploadCallLogo: build.mutation<{ filename: string }, { id: FlowCall['id']; data: FormData }>({
      ...withLoading,
      query: ({ id, data }) => ({
        url: `/v2/calls/${id}/logo`,
        method: 'POST',
        body: data,
        formData: true,
      }),
    }),
    deleteCallLogo: build.mutation<void, FlowCall['id']>({
      ...withLoading,
      query: (id) => ({
        url: `/v2/calls/${id}/logo`,
        method: 'DELETE',
      }),
    }),
    countApplied: build.query<CountAppliedResponse, FlowCall['id']>({
      ...withLoading,
      query: (id) => ({ url: `/v2/applications/count/${id}` }),
      transformResponse: (res: CountAppliedResponse, _meta, args) => ({ ...res, id: args }),
    }),
    getCallCutoffRoles: build.query<{ cutoffRoles: FlowCallCutoff; fixedRoles: ROLES[] }, GetCallCutoffRolesPayload>({
      query: ({ callId, cutOffId }) => ({ url: `/v2/calls/${callId}/roles/${cutOffId}` }),
    }),
    updateCutOffRole: build.mutation<FlowCall, {id: FlowCutOff['id']; roles: {role: ROLES; userGroupId?: number}[] }>({
      ...withLoading,
      query: ({ id, ...body}) => ({
        url: `/v2/calls/cutoffs/${id}/roles`,
        method: 'PUT',
        body,
      }),
    }),
    createCutOffRoles: build.mutation<UserRole[], CreateCutOffRolePayload>({
      ...withLoading,
      query: ({ cutOffId, ...body}) => ({
        url: `/v2/calls/cutoffs/${cutOffId}/roles`,
        method: 'POST',
        body,
      }),
    }),
    removeCutOffRoles: build.mutation<{ callRole?: { id: number }}, number[]>({
      ...withLoading,
      query: (ids) => ({
        url: `/v2/calls/role/ids=${ids.join(',')}`,
        method: 'DELETE',
      }),
    }),
    getCutoffRoleLists: build.query<GetCutoffRoleListsResponse, GetCutoffRoleListsPayload>({
      query: ({ callId, cutOffId }) => ({ url: `/v2/calls/${callId}/roles/${cutOffId}/lists` }),
    }),
  }),
})

export const {
  useGetCallsQuery,
  useLazyGetCallsQuery,
  useGetCallQuery,
  useGetCallIdsQuery,
  useCreateCallMutation,
  useUpdateCallMutation,
  useCloneCallMutation,
  useDeleteCallMutation,
  useUploadCallLogoMutation,
  useDeleteCallLogoMutation,
  useCountAppliedQuery,
  useLazyGetCallCutoffRolesQuery,
  useUpdateCutOffRoleMutation,
  useCreateCutOffRolesMutation,
  useRemoveCutOffRolesMutation,
  useGetCutoffRoleListsQuery,
  useLazyGetCutoffRoleListsQuery,
} = callsApi
