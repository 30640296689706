import * as React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Grid, newTheme, SvgIcon, Tooltip } from 'prace-common-components'
import { $Grid, $GridItem, $Save, $Warning } from './styles'
import { useAppSelector } from 'store/hooks'
import { RootState } from 'store'
import { validateEditCall } from 'util/validateEditCall'

// Use withAutoSave on the endpoints that should trigger the save icon
export const CallSaveStatus: React.FC = () => {
  const call = useAppSelector((state: RootState) => state.calls.call)
  const templates = useAppSelector((state: RootState) => state.calls.templates)
  const saving = useAppSelector((state: RootState) => state.loading.saving)
  const saved = useAppSelector((state: RootState) => state.loading.success)
  const saveFailed = useAppSelector((state: RootState) => state.loading.fail)
  const [showSaving, setShowSaving] = React.useState(false)
  const [showSave, setShowSave] = React.useState(false)
  const [showError, setShowError] = React.useState(false)

  const warning = call ? validateEditCall(call, templates) : ''

  /* TODO: Only easy way right now to fix the issue of broadcasting
   the refresh at the start because of store issues */
  React.useEffect(() => {
    window.localStorage.removeItem('broadcast')
    const timer = setTimeout(() => {
      window.localStorage.setItem('broadcast', 'true')
    }, 10000)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  React.useEffect(() => {
    if(!saved && !saveFailed && !saving) return
    if(saving) {
      setShowSaving(true)
      setShowSave(false)
      return
    }
    if(saveFailed) {
      setShowSaving(false)
      setShowError(true)
    } else {
      setShowSaving(false)
      setShowSave(true)
      /* Alert all the other users that this call was updated */
      if(window.localStorage.getItem('broadcast'))
        window.document.body.dispatchEvent(new Event('call-refresh', {
          bubbles: true,
          cancelable: false,
          composed: false,
        }))
    }
    const timer = setTimeout(() => {
      setShowError(false)
      setShowSave(false)
      setShowSaving(false)
    }, 4000)
    return () => {
      clearTimeout(timer)
    }
  }, [saved, saveFailed, saving])

  return (
    <>
      <$GridItem container justifyContent='flex-end' item xs={2}>
        {(showSave || showError) &&
          <Tooltip
            text='Your changes will only be applied on subsequent proposals'
            arrow
            show
            noIcon
            placement='top'
          >
            <$Grid container wrap='nowrap'>
              <SvgIcon name='check-outlined' color={showError ? newTheme.colors.feedback.error : newTheme.colors.feedback.success} size={20} />
              <$Save>{showError ? 'Save failed' : 'Changes Saved'}</$Save>
            </$Grid>
          </Tooltip>
        }
        {showSaving && 
        <$Grid container wrap='nowrap'>
          <CircularProgress size={16} />
          <$Save>Saving</$Save>
        </$Grid>
        }
      </$GridItem>
      {warning && <Grid container justifyContent='flex-end' item xs={1}>
        <Tooltip
          text={warning}
          arrow
          show
          noIcon
          placement='bottom'
        >
          <div>
            <$Warning name='warning' color={newTheme.colors.feedback.error} size={30} />
          </div>
        </Tooltip>
      </Grid>}
    </>
  )
}
